



























import { Component, Vue } from 'vue-property-decorator';
import errGif from '@/assets/401-images/401.gif';

@Component({
    name: 'Page401'
})
export default class extends Vue {
    public errGif = errGif + '?' + +new Date()

    public back() {
        if (this.$route.query.noGoBack) {
            this.$router.push({ path: '/home' });
        } else {
            this.$router.go(-1);
        }
    }
}
